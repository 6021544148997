import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { Layout } from "../layouts/layout"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { ComponentMotion } from "../utils/componentMotion"
import { Seo } from "../components/seo"
import { data } from "autoprefixer"

const AboutPage = ({ data }) => {
  let isEnglish = data.context.context.nodeLocale === "en-CA"
  const path = data.context.context.pathNoLanguagePrefix

  const { pictureFeature, text1, title } = data.page

  const richTextOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className="text-base xl:text-lg pb-3">{children}</div>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <div className="text-xl text-gray-900 font-semibold pt-4 pb-3">
          {children}
        </div>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <div className="text-large text-gray-900 font-normal underline pt-4 pb-3">
          {children}
        </div>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <div className="text-sm pb-3">{children}</div>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc pl-6">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="list-decimal pl-6 pb-0">{children}</ol>
      ),
      [INLINES.HYPERLINK]: ({ data }, children) => {
        return (
          <a
            href={data.uri}
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold text-pwxBlue hover:underline"
          >
            {children}
          </a>
        )
      },
    },
  }
  return (
    <Layout isEnglish={isEnglish} path={path}>
      <div className="relative">
        <div aria-hidden="true" className="">
          <GatsbyImage image={getImage(pictureFeature)} loading="eager" />
        </div>
        <div className="relative lg:absolute flex w-full lg:w-1/3 h-[90%] xl:h-4/5 lg:top-12 lg:right-12 bg-primary px-5 py-12 lg:p-12 rounded-lg">
          <div className="m-auto">
            <ComponentMotion viewThreshold={0} yMotion={200}>
              <h1 className="mb-4 lg:mb-8 uppercase tracking-widest font-extrabold text-secondary text-2xl lg:text-4xl">
                {title}
              </h1>
              {text1 && renderRichText(text1, richTextOptions)}
            </ComponentMotion>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const Head = ({ data }) => (
  <Seo
    title={data.page.title}
    image={getSrc(data.page.pictureFeature)}
    description={data.page.title}
  />
)

export const query = graphql`
  query About($slug: String!, $nodeLocale: String!) {
    page: contentfulAboutPage(
      slug: { eq: $slug }
      node_locale: { eq: $nodeLocale }
    ) {
      node_locale
      id
      pictureFeature {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      text1 {
        raw
      }
      title
      slug
    }
    context: sitePage(
      context: { nodeLocale: { eq: $nodeLocale }, slug: { eq: $slug } }
    ) {
      id
      path
      context {
        slug
        nodeLocale
        pathNoLanguagePrefix
      }
    }
  }
`
